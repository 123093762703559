import { Icon, EventIcon, TransportIcon } from "../common/Icon";
import { MAP_ID, TooltipIcon } from "./../common/Util";
import { useRef, useEffect, useState, Fragment, useCallback } from 'react';
import { resizeImage, makeLineGeojson } from "./MapEditUtil";
import ImageLoader from "../common/ImageLoader";
import { getStorage, ref, getBlob, uploadString, deleteObject } from "firebase/storage";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import * as STYLE from "./../common/Style";
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import PropTypes from 'prop-types';

export default function ItemEditor(props) {

  const t = window.t;
  const [imageCache, setImageCache] = useState(new Map());
  const [itemForm, setItemForm] = useState(null);
  const [imageForm, setImageForm] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const timeRef = useRef();
  const IMAGE_PATH = `user/${props.userMap.uid}/maps/${props.mapId}/`;

  const resizeImageCB = useCallback((image) => {
    setImageLoading(true);
    resizeImage(image, 400, (image) => {
      let imageName = null;
      if (image)
        imageName = itemForm.image ? itemForm.image : '' + (new Date()).getTime();
      setItemForm((itemForm) => {
        itemForm.image = imageName; 
        return itemForm
      });
      setImageForm(image);
      setImageLoading(false);
    });
  }, [itemForm]);

  useEffect(() => {
    props.userMap.features.forEach((item, index) => {
      if (item.properties.image && !imageCache.has(item.properties.image)) {
        const storage = getStorage();
        getBlob(ref(storage, IMAGE_PATH + item.properties.image), 1024*1024).then((blob) => {
          imageCache.set(item.properties.image, URL.createObjectURL(blob));
          setImageCache(new Map(imageCache));
        }).catch((error) => {
          console.error('image not found: ' + item.properties.image);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userMap.features]);

  const itemActionStyle = {
    display: 'flex', 
    justifyContent: 'flex-end'
  }

  const itemSeqStyle = {
    color:STYLE.COLOR_ON_SURFACE, 
    marginRight:'0.5em', 
    fontSize:'95%'
  }

  const itemAttrIconStyle = {
    display: 'flex', 
    alignItems: 'center'
  }

  const itemAttrContaiorStyle = {
    display: 'flex'
  }

  const iconStyle = {
    height: '18px',
    width: '18px',
    strokeWidth: 2
  }

  const addButtonStyle = {
    fontSize:'20px', 
    width:'24px'
  }
  
  const swapIconStyle = {
    height: '14px',
    width: '14px',
    strokeWidth: 2
  }

  const imageOuterStyle = {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    minHeight: '80px',
  }

  const itemImageStyle = {
    maxWidth: '80px', 
    maxHeight: '80px'
  }

  const lableStyle = {
    color: STYLE.COLOR_ON_SRFACE_VARIANT,
    marginRight: '10px',
    fontSize: '14px'
  }

  const descStyle = {
    fontSize: '90%'
  }

  const transContainerStyle = {
    display:'flex', 
    whiteSpace: 'nowrap', 
    padding: '0 1.5em 0.5em'
  }

  const transDescStyle = {
    marginLeft: '1em', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis',
    fontSize: '90%'
  }

  const dayTimeStyle = {
    marginLeft: '1em', 
    fontSize: '90%'
  }

  const imagePreviewStyle = {
    ...imageOuterStyle,
    flexGrow: 1
  }

  const imageActionStyle = {
    textAlign: 'center'
  }

  const imageStyle = {
    maxHeight: '100px',
    maxWidth: '100px'
  }

  const itemEditStyle = {
    display: 'grid', 
    gap: '8px',
    gridTemplateColumns: '100px 1fr 100px 1fr',
    gridTemplateRows: 'auto auto auto auto auto',
    margin: '16px 16px 0',
    width: '100%'
  }
  
  const editActionStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gridColumn: '1/5'
  }

  const dtEditStyle = {
    gridColumn: '1/5'
  }

  const selectStyle = {
    paddingTop:0, 
    height:'38px'
  }

  return (
    <List sx={{ width: 'auto'}}>
    {props.userMap.features.map((item, index) => {
      const SeletedEventIcon = item.properties.event ? EventIcon[item.properties.event].icon : null;
      const SelectedTrasportIcon = item.properties.transport ? TransportIcon[item.properties.transport].icon : null;
      const tm = item.properties.time ? new Date("1970-01-01T" + item.properties.time) : null;
      return <Fragment key={item.id}>
        <Divider variant="middle" >
          <IconButton 
            size="small"
            color="primary"
            sx={{p:0, ml:0.5, mr:0.5}}
            disabled={props.userMap.features.length >= 50 || props.editItem !== -1}
            onClick={() => insertNew(index)}
          >
            <span style={addButtonStyle} title={t.addItem}>+</span>
          </IconButton>
          {index === 0 ? null :
            <IconButton 
              size="small"
              color="primary"
              sx={{ml:0.5, mr:0.5}}
              disabled={props.editItem !== -1}
              onClick={() => {
                const features = props.userMap.features.concat();
                const temp = features[index];
                features[index] = features[index - 1];
                features[index - 1] = temp;
                renumbering(features);
                props.updateUserMap({features : features});
              }}
            >
              <Icon.Swap title={t.swapItem} style={swapIconStyle}/>
            </IconButton>
          }
        </Divider>
        {props.editItem !== item.id && (item.properties.lineTitle || item.properties.transport) ? 
          <>
            <div style={transContainerStyle}>
              <div style={itemAttrIconStyle}><span style={lableStyle}>{t.itemTransport}</span>{SelectedTrasportIcon ? <SelectedTrasportIcon style={iconStyle} /> : '-'}</div>
              <span style={transDescStyle}>{item.properties.lineTitle}</span>
            </div>
            <Divider variant="middle" />
          </> : null
        }
        <ListItem sx={{p: 0}}>
          {props.editItem === item.id ? 
            // Edit List Item
            <div style={itemEditStyle}>
              <Accordion style={{gridColumn: '1/5'}}>
                <AccordionSummary
                  sx={{fontSize: '90%'}}
                  expandIcon={<Icon.Accordion style={iconStyle} />}
                  aria-controls="transport"
                >
                  {t.itemTransport}
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl variant="filled" fullWidth sx={{minWidth: 120, mb: '15px'}}>
                    <Select 
                      style={selectStyle}
                      value={itemForm.transport ?? ""} 
                      onChange={(e) => setItemForm({...itemForm, transport : e.target.value === "" ? null : e.target.value})}
                    >
                      <MenuItem value="">{t.noSelect}</MenuItem>
                      {Object.keys(TransportIcon).map((transport) => {
                        const Icon = TransportIcon[transport].icon;
                        return (
                          <MenuItem key={transport} value={transport} >
                            <ListItemIcon sx={{minWidth:'28px', ml:'5px'}}><Icon style={iconStyle} /></ListItemIcon>
                            {t.transport[transport]}
                          </MenuItem>
                        );
                      }
                      )}
                    </Select>
                  </FormControl>
                  <TextField 
                    value={itemForm.lineTitle}
                    label={t.descLine}
                    fullWidth
                    onChange={(e) => setItemForm({...itemForm, lineTitle : e.target.value})}
                    inputProps={{maxLength: 20}}
                    variant="filled"
                    helperText={`${itemForm.lineTitle?.length ?? 0}/20`}
                    />
                  <TextField 
                    value={itemForm.polyline}
                    label={t.encodedPolylineStr}
                    fullWidth
                    onChange={(e) => setItemForm({...itemForm, polyline : e.target.value})}
                    inputProps={{maxLength: 5000}}
                    variant="filled"
                    helperText={`${itemForm.polyline?.length ?? 0}/5000`}
                    />
                </AccordionDetails>
              </Accordion>              

              <TextField
                value={itemForm.name ?? ''}
                label={t.itemName}
                inputProps={{maxLength: 50}}
                variant="filled"
                sx={{gridColumn: '1/5'}}
                helperText={`${itemForm.name?.length ?? 0}/50`}
                onChange={(e) => setItemForm({...itemForm, name : e.target.value})}
                fullWidth
              />
              <div>
                <div style={imagePreviewStyle}>
                  {imageForm ? 
                    <img alt={itemForm.name} style={imageStyle} src={imageForm}/> 
                    : 
                    <Icon.Image />
                  }
                </div>
                <div style={imageActionStyle}>
                  <ImageLoader 
                    buttonStyle={iconStyle} 
                    buttonType="icon"
                    onLoad={resizeImageCB}
                    disabled={imageLoading}
                  />
                  {imageForm ? 
                    <IconButton 
                      color="primary" 
                      size="small"
                      onClick={() => {
                        setImageForm(null);
                        setItemForm({...itemForm, image : null});
                      }}
                    >
                      <Icon.Delete style={iconStyle} title={t.delete}/>
                    </IconButton>
                  :
                    null}
                </div>
              </div>
              <TextField 
                value={itemForm.description}
                label={t.descMap}
                multiline
                rows={4}
                fullWidth
                onChange={(e) => setItemForm({...itemForm, description : e.target.value})}
                sx={{gridColumn: '2/5'}}
                inputProps={{maxLength: 150}}
                variant="filled"
                helperText={`${itemForm.description?.length ?? 0}/150`}
                />
              <FormControl variant="filled" fullWidth sx={{minWidth: 120, gridColumn: '1/5'}}>
                <InputLabel id="event-label">{t.itemEvent}</InputLabel>
                <Select 
                  style={selectStyle}
                  value={itemForm.event ?? ""} 
                  labelId="event-label" 
                  onChange={(e) => setItemForm({...itemForm, event : e.target.value === "" ? null : e.target.value})}
                >
                  <MenuItem value="">{t.noSelect}</MenuItem>
                  {Object.keys(EventIcon).map((event) => {
                    const Icon = EventIcon[event].icon;
                    return (
                      <MenuItem key={event} value={event} >
                        <ListItemIcon sx={{minWidth:'28px', ml:'5px'}}><Icon style={iconStyle} /></ListItemIcon>
                        {t.event[event]}
                      </MenuItem>
                    );
                  }
                  )}
                </Select>
              </FormControl>
              <div style={dtEditStyle}>
                <FormControl variant="filled" sx={{minWidth: 120}}>
                  <InputLabel id="day-label">{t.day}</InputLabel>
                  <Select 
                    value={itemForm.day ?? ""}
                    style={selectStyle}
                    labelId="day-label"
                    onChange={(e) => setItemForm({...itemForm, day : e.target.value === "" ? null : parseInt(e.target.value)})}
                  >
                    <MenuItem value={null}>{t.noSelect}</MenuItem>
                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((day) => 
                      <MenuItem key={day} value={day} >{t.dayFormatter(day)}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimeField
                    label={t.time}
                    clearable
                    inputRef={timeRef}
                    variant="filled"
                    defaultValue={itemForm.time ? dayjs(itemForm.time, "HH:mm") : null}
                    format={t.timeFormat}
                    sx={{width: 130, ml:1}}
                  />
                </LocalizationProvider>
                <TooltipIcon helpText={t.dayTimeTips}/>
              </div>
              <div style={editActionStyle}>
                <Button
                  size="small"
                  startIcon={<Icon.Ok style={iconStyle}/>} 
                  onClick={() => {
                    if (item.properties.image) {
                      if (imageForm) {
                        if (imageCache.get(item.properties.image) !== imageForm)
                          uploadImage();
                      } else {
                        imageCache.delete(item.properties.image);
                        deleteObject(ref(getStorage(), IMAGE_PATH + item.properties.image)).catch((e) => {
                          props.setSnackBarMsg(e.message);
                          console.error("Error delete document: ", e);
                        });
                      }
                    } else if (imageForm)
                      uploadImage();

                    const time = dayjs(timeRef.current.value, t.timeFormat);
                    itemForm.time = time.isValid() ? time.format("HH:mm") : null;

                    item.properties = itemForm;
                    const center = props.map.current.getCenter();
                    item.geometry.coordinates = [center.lng, center.lat]
                    const features = props.userMap.features.concat();
                    renumbering(features);
                    props.updateUserMap({features : features}, () => {
                      props.changeEditItem(-1, true);
                      props.map.current.getSource(MAP_ID.data).setData(props.userMap);
                      props.map.current.getSource(MAP_ID.lineData).setData(makeLineGeojson(props.userMap));
                    });
                  }}
                >{t.ok}
                </Button>
                <Button
                  onClick={() => props.changeEditItem(-1)}
                  size="small"
                  startIcon={<Icon.Cancel style={iconStyle}/>} 
                >{t.cancel}
                </Button>
              </div>
            </div>
            :
            <ListItemButton 
              sx={{pt:0, pb:0}}
              disabled={props.loading}
              onClick={() => {
                props.changeEditItem(item.id);
                props.map.current.setFeatureState(
                  { source: MAP_ID.data, id: item.id },
                  { edit: true }
                );
                setItemForm({
                  name: item.properties.name, 
                  description: item.properties.description, 
                  image: item.properties.image, 
                  time: item.properties.time, 
                  transport: item.properties.transport, 
                  event: item.properties.event,
                  day: item.properties.day,
                  polyline: item.properties.polyline ?? null,
                  lineTitle: item.properties.lineTitle ?? null,
                });
                setImageForm(item.properties.image ? imageCache.get(item.properties.image) : null);
                const seletedItem = props.userMap.features.find((e) => e.id === item.id);
                props.map.current.jumpTo({
                  center: seletedItem.geometry.coordinates
                });
              }
            }>
              <ListItemIcon sx={{width:90}} style={imageOuterStyle}>
                {item.properties.image && imageCache.get(item.properties.image) ?
                <img style={itemImageStyle} alt={item.properties.name} src={imageCache.get(item.properties.image)} /> 
                : null}
                {item.properties.image ? null : <Icon.Image/>}
              </ListItemIcon>
              <ListItemText
                primary={<><span style={itemSeqStyle}>{item.id}</span>{item.properties.name ?? t.noTitle}</>}
                secondary={<>
                  <div style={descStyle}>{item.properties.description}</div>
                  <div style={itemAttrContaiorStyle}>
                    <div style={itemAttrIconStyle}><span style={lableStyle}>{t.itemEvent}</span>{item.properties.event ? <SeletedEventIcon style={iconStyle} /> : '-'}</div>
                    <span style={dayTimeStyle}>{item.properties.day ? `${t.dayFormatter(item.properties.day)} ${tm ? t.tmFormater(tm) : ''}` : (tm ? t.tmFormater(tm) : '')}</span>
                  </div>
                  <div style={itemActionStyle}>
                    <IconButton
                      size="small"
                      color="primary"
                      // this botton is dummy. event should be written at outer ListItemButton.
                    >
                      <Icon.Edit style={iconStyle} title={t.edit}/>
                    </IconButton>
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={props.userMap.features.length <= 1 || props.editItem !== -1}
                      onClick={(e) => {

                        e.stopPropagation();
                        if (item.properties.image)
                          deleteObject(ref(getStorage(), IMAGE_PATH + item.properties.image)).catch((e) => {
                            props.setSnackBarMsg(e.message);
                            console.error("Error delete document: ", e);
                          });
                        const features = props.userMap.features.toSpliced(index, 1);
                        renumbering(features);
                        props.updateUserMap({features : features});        
                      }}
                    >
                      <Icon.Delete style={iconStyle} title={t.delete} />
                    </IconButton>
                  </div>
                </>}
                primaryTypographyProps={{sx:{ color: 'secondary.main' }}}
                secondaryTypographyProps={{variant: "div"}}
              />
            </ListItemButton>
          }
        </ListItem>
      </Fragment>;
    })}
    <Divider variant="middle" sx={{mb: 3}}>
      <IconButton 
        size="small"
        color="primary"
        sx={{p:0}}
        disabled={props.userMap.features.length >= 50 || props.editItem !== -1}
        onClick={() => insertNew(props.userMap.features.length)}
      >
        <span style={addButtonStyle} title={t.addNewItem}>+</span>
      </IconButton>
    </Divider>
  </List>

  );

  function renumbering(features) {
    let i = 1;
    features.forEach((item) => {
      item.id = i;
      i = i + 1;
    });
  }

  function insertNew(index) {
    const center = props.map.current.getCenter();
    const features = props.userMap.features.toSpliced(index, 0, {
      geometry: {
        coordinates: [center.lng, center.lat],
        type: 'Point'
      },
      id: Number.MAX_SAFE_INTEGER,
      properties: {},
      type: 'Feature'
    });
    props.setUserMap({ ...props.userMap, features: features });
    props.changeEditItem(Number.MAX_SAFE_INTEGER);
    setItemForm({
      name: null,
      description: '', // in case of set null, warning is occured?? 
      image: null,
      time: null,
      transport: null,
      event: null,
      day: null
    });
    setImageForm(null);
  }

  function uploadImage() {
    imageCache.set(itemForm.image, imageForm);
    const storageRef = ref(getStorage(), IMAGE_PATH + itemForm.image);
    uploadString(storageRef, imageForm, 'data_url').catch((e) => {
      props.setSnackBarMsg(e.message);
      console.error("Error adding/modify document: ", e);
    });
  }
};

ItemEditor.propTypes = {
  userMap: PropTypes.object,
  loading: PropTypes.bool,
  updateUserMap: PropTypes.func,
  editItem: PropTypes.number,
  changeEditItem: PropTypes.func,
  mapId: PropTypes.string,
  map: PropTypes.object,
  setSnackBarMsg: PropTypes.func,
  setUserMap: PropTypes.func,
}
