export const langs = ["en", "ja"];
const i18n = {
    "en" : {
        "lang": "en",
        "langName": "English",
        // Player
        "play": "Play",
        "pause": "Pause",
        "stop": "Stop",
        "auto": "Auto Play",
        // User Map
        "descMap": "Description",
        "twitter": "Share to X",
        "copyLink": "Copy link",
        "autoPlay": "Auto play",
        "urlLink": "Link",
        "shareLink": "Share",
        "copiedLink": "Copied to clipboard",
        "noTitle": "(No Title)",
        "satellite": "Satellite",
        "street": "Street Map",
        "simple": "Simple Map",
        "next": "Next",
        "prev": "Prev",
        "openGoogleMap": "View on Google map",
        "openNewWindow": "Open new window",
        // Map Edit
        "publish": "Publish",
        "delete": "Delete",
        "createNew": "New map",
        "editMap": "Edit map",
        "mapTitle": "Title",
        "defaultPoint" : [-73.96, 40.77],
        "mapInstractionNew": "Center first location, and click OK",
        "mapInstractionEdit": "Center the location",
        "toMap": "to Map",
        "confirmDelete": "Are you sure to delete this map?",
        "confirmPublish": "Are you sure to publish this map?",
        "reverse": "Play reverse (Countdown)",
        "baseDate": "Base date",
        "itemTransport": "Transportation",
        "itemEvent": "Event",
        "day": "Day",
        "dayFormatter": (day) => `Day ${day}`,
        "time": "Time",
        "addItem": "Add new item",
        "swapItem": "Swap items",
        "baseDateTips" : "(option) Input the date when specifying a specific date such as a travel plan or travelogue.",
        "reverseTips": "(option) Plays in reverse order during automatic playback Ranking information can be played in countdown format.",
        "dayTimeTips" : "(option) Enter the time of this event when creating a travel plan or travelogue.",
        "itemName" : "Name",
        "descItem": "Description",
        "noSelect": "(none)",
        "encodedPolylineStr": "Encoded Polyline String",
        "descLine": "Description",
        // Authentication
        "login": "Sign in / Login",
        "loginGoogle": "Sign in / Login with Google",
        "loginX": "Sign in / Login with X",
        "reAuth": "Re-authentication required",
        "logout": "Logout",
        "deactivate": "Deactivate your account",
        "linked": "Linked",
        "link": "Link",
        "notLinked": "Not linked",
        "unlink": "Unlink",
        "authenticaton": "Authenticaton",
        "deactive": "Deactivate",
        "alreadyLinked": "The credential has been use with other account already.",
        "deactiveCaption": "If you deactivate your account, all information will be deleted within a few days. \nThis operation cannot be undone.",
        "deactiveConfirmation": "Are you sure to deactivate your account?",
        "poupBlocked": "Please remove pop-up blocker.",
        "createAccount": "Create your account",
        "termConfirmation": "Please be sure to read and agree Terms of Use and Privacy Policy and sign in / login with one of your accounts.", 
        "userNameUsed": "The username you entered is being used by another account",
        // Main
        "trend": "Trend",
        "howToUse": "How to Use",
        "siteDescription": "Shere your favorite places with map!",
        "privacyPolicy": "Privacy Policy",
        "termsOfUse": "Terms of Use",
        "pickup": "Featured map",
        "contactUs": "Contact us",
        "aboutUs": "About Toboggar",
        "aboutContactUs": "About/Contact us",
        "contentsPolicy": "Contents Policy",
        "contactForm": "Contact Form",
        "managedBy": "Toboggar is managed by hiroatsu (ひろあつ).",
        // Profile
        "userName": "username",
        "name": "name",
        "profile": "Profile",
        "language": "language",
        "selectImage": "Select Image",
        "userNameChar": "Your username can contain only letters, numbers, and underscores—no spaces are allowed.",
        "nameLength": "Your display name can be up to 30 characters long.",
        "userNameLength": "Your username must be more than 4 characters long and can be up to 15 characters or less.",
        "nameNeverChange": "Your username is a unique ID and cannot be changed in any way once created.",
        "recommendMultiAuth": "For your safety, we recommend linking with multiple authentication methods to avoid loss of authentication methods.",
        // Search
        "search": "Search",
        "getSearchStat": (count, duration) => `${count} results found in ${duration}ms`,
        "previousPage": "Show previous results",
        "morePage": "Show more results",
        // Common
        "toHome": "Home",
        "ok": "OK",
        "cancel": "Cancel",
        "generalError": "Oops! Something wrong!",
        "back": "Back",
        "draft": "Draft",
        "edit": "Edit",
        "like": "Like",
        "share": "Share",
        "reload": "Reload",
        "up": "Up",
        "add": "Add new map",
        "required": "Required",
        "optional": "Optional",
        "upload": "Upload",
        // Rule
        "ruleTranslateRemark1": "This term was made in Japanese and have been translated machine tranlated. The original Japanese term is ",
        "ruleTranslateRemark2": "here",
        "ruleTranslateRemark3": ".",
        // How to use
        "howtoFavorite": "Share your favorit places!",
        "howtoPlanTrip": "Share your travelogue or trip plan!",
        "howtoMapping": "Map your favorit places on your map.",
        "howtoCountdown": "Turn on 'Play Reverse' and playing in countdown.",
        "howtoModelCourse": "When introducing a your trip plan, enter the day and time.",
        "howtoTravelog": "When writing your travelogue, enter the date you started your trip.",
        "howtoStart" : {prefix: "Start from", suffix: ""},
        "howtoPublish" : {prefix: "Click", suffix: "to share"},
        "howtoShare" : {prefix: "Click", suffix: "to publish"},
        "guindingToSignin": "Sign in and you will be able to create your maps.", 
        // Event
        event : {
            resort: "Resort",
            gourmet: "Gourmet",
            entertainment: "Entertainment",
            stay: "Stay",
            sports: "Sports",
            nature: "Nature",
            shopping: "Shopping",
            outdoor: "Outdoor",
            drive: "Drive",
            party: "Party",
            art: "Art",
            monument: "Monument"
        },
        // Transport
        transport : {
            air: "Air",
            ferry: "Ferry",
            walk: "Walk",
            bus: "Bus",
            train: "Train",
            bicycle: "Bicycle",
            car: "Car",
            toboggan: "Toboggan"
        },
        // Formatter
        "calendarFormat": null,
        "dateFormat": "MMM DD, YYYY",
        "timeFormat": "hh:mm A",
        "dtFormater" : (dt) => new Intl.DateTimeFormat('en-US', {year: "numeric", month: "short",  day: "numeric", hour: "numeric",  minute: "numeric"}).format(dt),
        "tmFormater" : (tm) => new Intl.DateTimeFormat('en-US', {hour: "numeric",  minute: "numeric"}).format(tm),
        "dFormater" : (d) => new Intl.DateTimeFormat('en-US', {year: "numeric", month: "short",  day: "numeric"}).format(d)
    },
    "ja" : {
        "lang": "ja",
        "langName": "日本語",
        // Player
        "play": "再生",
        "pause": "一時停止",
        "stop": "停止",
        "auto": "Auto Play",
        // User Map
        "descMap": "詳細",
        "twitter": "Xでシェア",
        "copyLink": "リンクをコピー",
        "autoPlay": "自動再生",
        "urlLink": "リンク",
        "shareLink": "シェア",
        "copiedLink": "クリップボードにコピーしました",
        "noTitle": "(無題)",
        "satellite": "衛星写真",
        "street": "道路地図",
        "simple": "シンプル",
        "next": "次",
        "prev": "前",
        "openGoogleMap": "Google map で開く",
        "openNewWindow": "別画面で表示",
        // Map Edit
        "publish": "公開",
        "delete": "削除",
        "createNew": "新規作成",
        "editMap": "編集",
        "mapTitle": "タイトル",
        "defaultPoint" : [139.75, 35.68],
        "mapInstractionNew": "中心に最初の地点を合わせてOKをクリック",
        "mapInstractionEdit": "編集地点を中心に合わせる",
        "toMap": "地図表示",
        "confirmDelete": "地図を削除しますか?",
        "confirmPublish": "公開しますか?",
        "reverse": "逆順再生（カウントダウン）",
        "baseDate": "基準日",
        "itemTransport": "移動方法",
        "itemEvent": "イベント",
        "day": "日程",
        "dayFormatter": (day) => `${day}日目`,
        "time": "時刻",
        "addItem": "追加",
        "swapItem": "入れ替え",
        "baseDateTips" : "(オプション) 旅行プランや旅行記など特定の日付指定する場合に日付を指定します",
        "reverseTips": "(オプション) 自動再生時に逆順で再生します ランキング情報をカウントダウン形式で再生できます",
        "dayTimeTips" : "(オプション) 旅行プランや旅行記など作成時、このイベントの時刻を入力します",
        "itemName" : "名称",
        "descItem": "説明",
        "noSelect": "(無し)",
        "encodedPolylineStr": "Encoded Polyline 文字列",
        "descLine": "説明",
        // Authentication
        "login": "サインイン / ログイン",
        "loginGoogle": "Googleで サインイン / ログイン",
        "loginX": "Xで サインイン / ログイン",
        "reAuth": "再認証が必要です",
        "logout": "ログアウト",
        "deactivate": "退会する",
        "linked": "接続済み",
        "notLinked": "未接続",
        "link": "リンク",
        "unlink": "リンク解除",
        "authenticaton": "認証",
        "deactive": "退会",
        "alreadyLinked": "他のアカウントと既に連携済みです",
        "deactiveCaption": "退会されますと、数日以内に全ての情報が削除されます。\nこの操作は取り消しできません。",
        "deactiveConfirmation": "本当に退会しますか?",
        "poupBlocked": "ポップアップブロックを解除してください",
        "createAccount": "アカウント作成",
        "termConfirmation": "利用規約とプライバシーポリシーをご確認いただき、内容に同意の上、いずれかのアカウントで サインイン / ログイン を行ってください。", 
        "userNameUsed": "入力されたユーザーネームは既に使用されています",
        // Main
        "trend": "トレンド",
        "howToUse": "使い方",
        "siteDescription": "地図をシェアするSNS お気に入りの場所をシェアしよう",
        "privacyPolicy": "プライバシーポリシー （個人情報保護方針）",
        "termsOfUse": "利用規約",
        "contentsPolicy": "コンテンツポリシー",
        "pickup": "ピックアップ",
        "contactUs": "お問い合わせ",
        "aboutUs": "Toboggar について",
        "aboutContactUs": "Toboggar について(お問い合わせ)",
        "contactForm": "問い合わせフォーム",
        "managedBy": "Toboggar は ひろあつ が運営しております",
        // Profile
        "userName": "ユーザーネーム",
        "name": "名前",
        "profile": "プロフィール",
        "language": "言語",
        "selectImage": "画像を選択",
        "userNameChar": "ユーザーネームは英数および'_'を使用できます",
        "nameLength": "名前は最大30文字までです",
        "userNameLength": "ユーザーネームは5文字以上最大15文字までです",
        "nameNeverChange": "ユーザーネームは一意なIDで、一度作成した後は一切変更できません",
        "recommendMultiAuth": "認証手段を失わないために、複数の認証手段とリンクしておくと安全です",
        // Search
        "search": "検索",
        "getSearchStat": (count, duration) => `${count} 件 (${duration} ミリ秒)`,
        "previousPage": "前の結果を表示",
        "morePage": "さらに表示",
        // Common
        "toHome": "Home",
        "ok": "OK",
        "cancel": "取り消し",
        "generalError": "Oops! Something wrong!",
        "back": "戻る",
        "draft": "下書き",
        "edit": "編集",
        "like": "スキ",
        "share": "シェア",
        "reload": "再読み込み",
        "up": "上へ",
        "add": "新規地図作成",
        "required": "必須項目",
        "optional": "任意項目",
        "upload": "アップロード",
        // Rule
        "ruleTranslateRemark1": "本規約は日本語で作成され、各言語に機械翻訳されています。原文の日本語は ",
        "ruleTranslateRemark2": "こちら",
        "ruleTranslateRemark3": " です。",
        // How to use
        "howtoFavorite": "お気に入りの場所をシェアしよう!",
        "howtoPlanTrip": "旅行記やモデルコースをシェアしよう!",
        "howtoMapping": "お気に入りの場所を地図にマッピング",
        "howtoCountdown": "逆順をONにして、カウントダウン形式で紹介しよう",
        "howtoModelCourse": "モデルコースを紹介するときは、日程、時間を入力しよう",
        "howtoTravelog": "旅行記を書くときには、旅行を開始した日付を入力しよう",
        "howtoStart" : {prefix: "", suffix: "からスタート"},
        "howtoPublish" : {prefix: "", suffix: "で公開"},
        "howtoShare" : {prefix: "", suffix: "でシェア"},
        "guindingToSignin": "サインインするとマップを作成できるようになります。", 
        // Event
        event : {
            resort: "リゾート",
            gourmet: "グルメ",
            entertainment: "エンターテインメント",
            stay: "宿泊",
            sports: "スポーツ",
            nature: "自然",
            shopping: "ショッピング",
            outdoor: "アウトドア",
            drive: "ドライブ",
            party: "パーティー",
            art: "芸術",
            monument: "名所旧跡"
        },
        // Transport
        transport : {
            air: "飛行機",
            ferry: "船",
            walk: "徒歩",
            bus: "バス",
            train: "鉄道",
            bicycle: "自転車",
            car: "自動車",
            toboggan: "トボガン"
        },
        // Formatter
        "dateFormat": "YYYY/MM/DD",
        "timeFormat": "HH:mm",
        "calendarFormat": { monthAndYear: "YYYY年 M月", monthShort: "M月"},
        "dtFormater" : (dt) => `${new Intl.DateTimeFormat('ja-JP', {dateStyle: 'short', timeStyle: 'short'}).format(dt)}`,
        "tmFormater" : (tm) => `${new Intl.DateTimeFormat('ja-JP', {timeStyle: 'short'}).format(tm)}`,
        "dFormater" : (d) => `${new Intl.DateTimeFormat('ja-JP', {dateStyle: 'short'}).format(d)}`
    }
}
export default i18n;